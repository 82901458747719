import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  VStack,
  Textarea,
  HStack,
} from "@chakra-ui/react";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import colors from "../../constants/colors/colors";
import { Button } from "@chakra-ui/react";

const ContactUs = () => {
  // State variables to manage the form fields and captcha
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [desc, setDesc] = useState("");
  const [phone, setPhone] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle captcha change
  const onChange = (value) => {
    setCaptchaValue(value);
    if (value && name && email && phone && desc) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    // Validate the form fields
    if (!name || !phone || !email || !desc) {
      alert("Please fill out all fields.");
      return;
    }

    // Check if captcha is verified
    if (!captchaValue) {
      alert("Please verify the captcha");
      return;
    }

    const formData = {
      name,
      phone,
      email,
      desc,
    };

    console.log("Form Data: ", formData); // Log the form data

    setIsSubmitting(true); // Set submitting state

    try {
      // Send the form data to the API using axios
      const response = await axios.post(
        "https://xfc4bgj9i0.execute-api.ap-south-1.amazonaws.com/determinantmailer", // API endpoint
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response: ", response); // Log the response
      alert("Message sent successfully!");
      setIsSubmitting(false); // Reset the submitting state
    } catch (error) {
      console.error("Error: ", error); // Log the error to understand what went wrong
      alert("Error sending message. Please try again.");
      setIsSubmitting(false); // Reset the submitting state
    }
  };

  return (
    <Box
      bgGradient="linear-gradient(to bottom, #ffffff 80%, #f2e18d 20%)"
      py={10}
      px={{ base: 6, md: 12 }}
      borderRadius="md"
      id="contact"
    >
      <Flex
        maxW="1200px"
        mx="auto"
        direction={{ base: "column", md: "row" }}
        gap={10}
      >
        {/* Left Column: Contact Form */}
        <VStack
          align="stretch"
          flex="1"
          spacing={6}
          bg="white"
          p={8}
          boxShadow="xl"
          borderRadius="lg"
        >
          <Heading
            fontSize={{ base: "2xl", md: "3xl" }}
            color="#feb101"
            textAlign="center"
            textDecoration="underline"
          >
            Contact Us
          </Heading>

          {/* Name and Phone number in row */}
          <Flex gap={6} width="100%">
            <FormControl isRequired flex="1">
              <FormLabel fontSize="md" color="gray.600">
                Name
              </FormLabel>
              <Input
                type="text"
                placeholder="Enter your name"
                focusBorderColor={colors.primary}
                borderColor="gray.300"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </FormControl>

            <FormControl isRequired flex="1">
              <FormLabel fontSize="md" color="gray.600">
                Contact No.
              </FormLabel>
              <Input
                type="tel"
                placeholder="Enter your contact number"
                focusBorderColor={colors.primary}
                borderColor="gray.300"
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </FormControl>
          </Flex>

          {/* Email and Message in a single row */}

          <FormControl isRequired flex="1">
            <FormLabel fontSize="md" color="gray.600">
              Email
            </FormLabel>
            <Input
              type="email"
              placeholder="Enter your email"
              focusBorderColor={colors.primary}
              borderColor="gray.300"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FormControl>

          <FormControl isRequired flex="1">
            <FormLabel fontSize="md" color="gray.600">
              Message
            </FormLabel>
            <Textarea
              placeholder="Write your message here"
              focusBorderColor={colors.primary}
              borderColor="gray.300"
              onChange={(e) => setDesc(e.target.value)}
              rows={4}
              required
            />
          </FormControl>

          {/* Captcha and Submit button */}
          <Flex gap={6}>
            <FormControl isRequired>
              <ReCAPTCHA
                sitekey="6Lf_nPMqAAAAAHe9OdWrUv-Q_hsDYrEhH6g6lvtQ"
                onChange={onChange}
              />
            </FormControl>
            <Button
              size="lg"
              isDisabled={isSubmitDisabled || isSubmitting}
              onClick={handleSubmit}
              bg="#383838"
              color="white"
              _hover={{ bg: "#444" }} // Slight hover effect
              _focus={{ boxShadow: "none" }} // Remove focus box-shadow
              _active={{ bg: "#383838" }} // Active state styling
              mt={5}
            >
              {isSubmitting ? "Sending..." : "Send Message"}
            </Button>
          </Flex>
        </VStack>

        {/* Right Column: Contact Information */}
        <VStack
          align="start"
          flex="1"
          spacing={6}
          p={8}
          bg="#383838" // Changed background color for the right column
          boxShadow="xl"
          borderRadius="lg"
          height={"50%"}
          mt={20}
        >
          <Heading
            fontSize={{ base: "2xl", md: "3xl" }}
            color="white" // Text color for the heading in the right column
            mb={6}
            textAlign="center"
          >
            Get In Touch...
          </Heading>
          <VStack align="start" spacing={6}>
            <HStack spacing={4}>
              <FaPhoneAlt color="white" />
              <Text fontSize="lg" color="white">
                +91-9980331762
              </Text>
            </HStack>
            <HStack spacing={4}>
              <FaEnvelope color="white" />
              <Text fontSize="lg" color="white">
                Contact@divisha.io
              </Text>
            </HStack>
            <HStack spacing={4} align="start">
              <FaMapMarkerAlt color="white" />
              <Text fontSize="lg" color="white">
                S3, #104, 13th Main, 5th Sector, HSR Layout,
                <br />
                Bangalore - 560102, Karnataka, India
              </Text>
            </HStack>
          </VStack>
        </VStack>
      </Flex>
    </Box>
  );
};

export default ContactUs;
